import React from "react";
import { BanksDetails } from "../../components/BankDetails";
import { Col, Row } from "react-bootstrap";

function AdmissionPaymentDetails() {
  return (
    <>
      <h5 className="fw-bolder text-muted">Payment and Banking Details</h5>

      <Row className="mt-2">
        <Col md>
          <p className="fw-bold mb-1">
            <em>Application fee Payment</em>
          </p>

          <BanksDetails slug="undergraduate" />
        </Col>

        <Col md>
          <p className="fw-bold mb-1">
            <em>Fee Payment</em>
          </p>

          <BanksDetails slug="undergraduate" />
        </Col>
      </Row>
    </>
  );
}

export default AdmissionPaymentDetails;
