import React from "react";
import { Slider } from "../components/Slider";
import {
  Carousel,
  Col,
  Container,
  Image,
  NavLink,
  Row,
  Stack,
} from "react-bootstrap";
import { Data, Images } from "../assets/statics";
import { portalUrl } from "../assets/utils";

function Index() {
  return (
    <>
      <Slider />

      <section className="bg-white">
        <Container>
          <Row className="justify-content-center gy-2 justify-content-start-sm">
            {Data.HomeActions.map((data, index) => (
              <Col sm={4} md={3} key={`action_${index}`}>
                <NavLink
                  target="_blank"
                  rel="no-referrer"
                  href={`${portalUrl}/${data?.link}`}
                >
                  <Row className="text-start text-sm-center">
                    <Col className="col-3 col-sm-12">
                      <Image
                        width={56}
                        roundedCircle
                        className="mb-1"
                        src={`/images/icons/${data.image}`}
                        alt={data.title?.toLocaleLowerCase()}
                      />
                    </Col>
                    <Col className="col-9 col-sm-12">
                      <h4 className="fs-3 fw-bold">{data.title}</h4>
                      <p>{data.description}</p>
                    </Col>
                  </Row>
                </NavLink>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      <section className="bg-light">
        <Container>
          <Row className="gy-3 align-items-center">
            <Col md={3} className="px-md-0 px-5">
              <Image
                fluid
                thumbnail
                roundedCircle
                src={Images.VCPImage}
                alt="head_photo"
              />
            </Col>
            <Col className="text-center px-4 text-md-start">
              <h5 className="fw-bold fs-2 fs-md-4">
                Welcome to the Ernest Bai Koroma University of Science and
                Technology
              </h5>
              <Stack gap={2} className="text-justify">
                <em>
                  On behalf of the Ernest Bai Koroma University of Science and
                  Technology, it is my pleasure to welcome you to our esteemed
                  institution. As the Vice-Chancellor and Principal, I am
                  honoured to extend my warmest greetings to all of you.
                </em>

                <em>
                  As a science and technology-focused institution, we strive to
                  equip our students with the skills and knowledge needed to
                  tackle the challenges of our rapidly evolving world. Our
                  faculty members are renowned experts in their respective
                  fields, and our facilities are state-of-the-art.
                </em>

                <em>
                  We are proud of our diverse community of students, faculty,
                  and staff, who come from all walks of life and bring unique
                  perspectives and experiences to our university. We believe
                  that this diversity strengthens our institution and prepares
                  our graduates to be global citizens.
                </em>

                <em>
                  During your visit, we hope you will have the opportunity to
                  explore our campus, engage with our faculty and students, and
                  learn more about our academic programs and research
                  initiatives. We are confident that you will be impressed by
                  the talent and dedication of our community and the impact of
                  our work.
                </em>

                <em>
                  We are delighted to have you here, and we look forward to
                  sharing our passion for education and innovation with you.
                </em>

                <strong>Thank You.</strong>
              </Stack>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="bg-white">
        <Container className="py-4">
          <h4 className="fw-bold mb-4 display-6 text-center">
            Calendar of Activities
          </h4>

          <Row className="m-0 gy-2 mb-4 justify-content-center">
            {Data.Calendar.sort((a, b) => a.start.year < b.start.year)
              .slice(0, 6)
              .map(
                (activity, index) =>
                  !activity.isInternal && (
                    <Col lg={6} key={`activity_${index}`}>
                      <Row
                        direction="horizontal"
                        gap={3}
                        className="align-items-center h-100 border border-1 border-dark"
                      >
                        <Col sm={5} className="text-bg-light sm-h-100">
                          <Stack
                            direction="horizontal"
                            gap={2}
                            className="align-items-center h-100 justify-content-center"
                          >
                            <Stack
                              gap={1}
                              direction="horizontal"
                              className="align-items-center justify-content-center"
                            >
                              <span className="fs-1 fw-bold">
                                {activity.start.date?.padStart(2, "0")}
                              </span>
                              <Stack className="justify-content-center">
                                <small>{activity.start.month}</small>
                                <small>{activity.start.year}</small>
                              </Stack>
                            </Stack>

                            {activity?.end && (
                              <>
                                <span>&mdash;</span>

                                <Stack
                                  gap={1}
                                  direction="horizontal"
                                  className="align-items-center justify-content-center"
                                >
                                  <span className="fs-1 fw-bold">
                                    {activity.end.date?.padStart(2, "0")}
                                  </span>
                                  <Stack className="justify-content-center">
                                    <small>{activity.end.month}</small>
                                    <small>{activity.end.year}</small>
                                  </Stack>
                                </Stack>
                              </>
                            )}
                          </Stack>
                        </Col>

                        <Col sm className="text-center text-sm-start py-3">
                          <p className="fs-4 m-0">{activity?.event}</p>
                          {activity?.description && (
                            <p className="lead">
                              {activity.description?.slice(0, 64)}...
                            </p>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  )
              )}
          </Row>
        </Container>
      </section>

      <section className="bg-light news">
        <Container>
          <h4 className="fw-bold display-6 text-center mb-5">News</h4>
          <Carousel pause={false} indicators={false}>
            {Data.News.map((news, index) => (
              <Carousel.Item
                as="a"
                href={`/news-and-media/news/${news.slug}`}
                className="text-decoration-none text-secondary"
                key={`news_${index}`}
              >
                <Row>
                  <Col md={5}>
                    <Image
                      className="d-block w-100 rounded object-fit-cover"
                      src={`/images/news/${news.legend}`}
                      alt={news.title}
                      height={256}
                    />
                  </Col>
                  <Col md={6}>
                    <h3 className="fw-semibold">{news.headline}</h3>
                    <p
                      className="lead text-justify"
                      dangerouslySetInnerHTML={{ __html: news.extract }}
                    ></p>
                  </Col>
                </Row>
              </Carousel.Item>
            ))}
          </Carousel>
        </Container>
      </section>

      <section className="bg-white news">
        <Container>
          <Carousel pause={false} indicators={false}>
            {Data.News.reverse().map((news, index) => (
              <Carousel.Item
                as="a"
                href={`/news-and-media/news/${news.slug}`}
                className="text-decoration-none text-secondary"
                key={`news_${index}`}
              >
                <Row className="justify-content-end">
                  <Col md={6}>
                    <h3 className="fw-semibold">{news.headline}</h3>
                    <p
                      className="lead text-justify"
                      dangerouslySetInnerHTML={{ __html: news.extract }}
                    ></p>
                  </Col>
                  <Col md={5}>
                    <Image
                      className="d-block w-100 rounded object-fit-cover"
                      src={`/images/news/${news.legend}`}
                      alt={news.title}
                      height={256}
                    />
                  </Col>
                </Row>
              </Carousel.Item>
            ))}
          </Carousel>
        </Container>
      </section>

      <section className="glance">
        <Container className="text-white">
          <div className=" mb-5 text-center text-shadow ">
            <h2 className="fw-bold fs-2 fs-md-4 text-white text-uppercase mb-0">
              University at a Glance
            </h2>
            <p className="lead px-5 fw-semibold">
              Ernest Bai Koroma University of Science and Technology is one of
              the public universities in Sierra Leone, West Africa.
            </p>
          </div>

          <Row className="justify-content-center">
            {Data.Glance.map((glance, index) => (
              <Col md={3} key={`glance_${index}`}>
                <div className="text-shadow p-3 border text-center">
                  <h4 className="display-6">
                    {Intl.NumberFormat().format(glance.count)}+
                  </h4>
                  <p className="fw-bold lead">{glance.tittle}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Index;
