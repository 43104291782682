import React from "react";
import {
  Col,
  Container,
  FormControl,
  Image,
  Nav,
  NavDropdown,
  NavLink,
  Row,
} from "react-bootstrap";
import { Images } from "../assets/statics";
import { Link } from "react-router-dom";
import { portalUrl } from "../assets/utils";

export const TopNav = () => {
  return (
    <div className="border-bottom text-bg-white" id="top-nav">
      <Container className="py-2">
        <Row className="gy-3 justify-content-between align-items-center">
          <Col>
            <Link to="/" className="nav-link max-w-fit">
              <Image src={Images.LogoSVG} width={286} fluid />
            </Link>
          </Col>

          <Col md xl={4}>
            <div
              className="d-flex justify-content-end align-items-center"
              style={{ fontSize: ".7rem" }}
            >
              <small className="fw-bold">Call support:</small>
              <NavLink href="tel: 23276780302" className="ms-1">
                <small>(232) 76 780-302</small>
              </NavLink>
            </div>

            <FormControl
              type="search"
              size="sm"
              className="bg-light"
              placeholder="Search..."
            />

            <Nav className="justify-content-center justify-content-sm-end truncate fw-bold">
              <Nav.Link
                href={`${portalUrl}/studentregistration.aspx`}
                target="_black"
                rel="no-referrer"
              >
                <small className="hover-underline text-secondary">
                  Register
                </small>
              </Nav.Link>

              <Nav.Link disabled className="text-secondary">
                |
              </Nav.Link>

              <NavDropdown
                align="end"
                title={
                  <small className="hover-underline text-secondary">
                    Portal
                  </small>
                }
              >
                <NavDropdown.Item
                  href={`${portalUrl}/stulogin.aspx`}
                  className="dropdown"
                  target="_black"
                  rel="no-referrer"
                >
                  Student
                </NavDropdown.Item>

                <NavDropdown.Item
                  href={portalUrl}
                  className="dropdown"
                  target="_black"
                  rel="no-referrer"
                >
                  Staff
                </NavDropdown.Item>

                <NavDropdown.Item
                  href="https://qrcode.ebkustsl.edu.sl"
                  className="dropdown"
                  target="_black"
                  rel="no-referrer"
                >
                  QR Generator
                </NavDropdown.Item>

                <NavDropdown.Item
                  href="https://mat.ebkustsl.edu.sl"
                  className="dropdown"
                  target="_black"
                  rel="no-referrer"
                >
                  <span>
                    <abbr title="Matriculation">MAT</abbr> Generator
                  </span>
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link disabled className="text-secondary">
                |
              </Nav.Link>

              <Nav.Link
                href={`${portalUrl}/studentcheck.aspx`}
                target="_black"
                rel="no-referrer"
              >
                <small className="hover-underline text-secondary">Apply</small>
              </Nav.Link>
            </Nav>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
