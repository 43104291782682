import Routes from "../static/routes.json";
import Places from "../static/places.json";
import Calendar from "../static/calendar.json";
import HomeActions from "../static/HomeActions.json";
import Faculties from "../static/faculties.json";
import Departments from "../static/departments.json";
import Programs from "../static/programs.json";
import News from "../static/news.json";
import Management from "../static/management.json";
import Resources from "../static/resources.json";
import Affiliations from "../static/affiliations.json";
import GeoData from "../static/geo-data/data.json";
import Glance from "../static/glance.json";
import Locations from "../static/geo-data/locations.json";

import LogoSVG from "../static/images/Logo.svg";
import VCPImage from "../static/images/VCP.png";
import NotFoundImage from "../static/images/OIP.jpg";
import CoverImage from "../static/images/cover.png";
import LoadingImage from "../static/images/loading.gif";
import NoDataImage from "../static/images/no-data.webp";

export const Images = {
  LogoSVG,
  VCPImage,
  CoverImage,
  NoDataImage,
  LoadingImage,
  NotFoundImage,
};

export const Data = {
  Routes,
  Places,
  News,
  Calendar,
  HomeActions,
  Faculties,
  Resources,
  Departments,
  Affiliations,
  Programs,
  GeoData,
  Glance,
  Locations,
  Management,
};

const Static = {
  ...Images,
  ...Data,
};

export default Static;
