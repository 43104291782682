import React, { useMemo } from "react";
import { Data } from "../../assets/statics";
import { Col, Image, NavLink, Row, Stack } from "react-bootstrap";

const args = {
  faculty: {},
};

function FacultyAbout(props = { ...args }) {
  props = { ...args, ...props };

  const departments = useMemo(() => {
    return Data.Departments.filter(
      (d) => d.faculty === props.faculty?.slug
    ).map((d) => ({
      ...d,
      programs: Data.Programs.undergraduate.programs.filter(
        (p) => p.department === d.slug
      ),
    }));
  }, [props]);

  return (
    <>
      <h5 className="mb-3">{props.faculty?.name}</h5>

      <p className="text-justify d-inline mb-4">
        <img
          src={`/images/icons/${props.faculty?.icon}`}
          alt={`${props.faculty?.slug}_icon`}
          width={196}
          align="left"
          className="img-thumbnail position-static me-3"
        />
        <span className="text-justify">{props.faculty?.description}</span>
      </p>

      <section id="structure" className="py-0">
        <h5>Faculty Structure</h5>

        <section id="dean" className="py-0 mb-3">
          <p className="lead mb-1">Dean of Faculty</p>

          <Row className="mt-3">
            <Col md className="order-1 order-sm-0">
              <p className="fs-4 fw-bold text-muted mb-0">
                {props.faculty?.dean?.name}
              </p>
              <p
                className="text-justify m-1"
                style={{
                  overflow: "hidden",
                  lineHeight: "1.5rem",
                  maxHeight: "7.5rem",
                }}
              >
                {props.faculty?.dean?.bio}
              </p>
              <a
                href={`/faculty/${props.faculty?.slug}/dean`}
                className="text-info fw-semibold hover-underline"
              >
                Read more...
              </a>
            </Col>

            <Col md={3} className="order-0 order-sm-1">
              <Image
                src={`/images/deans/${props.faculty?.dean?.photo}`}
                alt={`photo_dean_${props.faculty?.slug}`}
                className="img-fluid img-thumbnail"
              />
            </Col>
          </Row>
        </section>

        <section id="dean" className="py-0 mb-2">
          <p className="lead mb-3">Departments</p>

          {departments.map((department) => (
            <div className="mb-3 pb-1" key={department.slug}>
              <Stack
                gap={3}
                direction="horizontal"
                className="justify-content-between align-items-end fw-semibold text-bg-light py-1 px-2 mb-2"
              >
                <span>{department.name}</span>

                <NavLink
                  href={`/faculty/${props.faculty?.slug}/departments/${department.slug}`}
                  className="text-primary min-w-fit"
                >
                  <small className="text-uppercase">view all</small>
                </NavLink>
              </Stack>

              <div className="px-0 px-sm-3">
                <section className="py-0 mb-2">
                  <p className="mb-0 fw-semibold">Head of Department</p>
                  <p className="lead mb-0">{department.hod.name}</p>
                  <small className="fw-semibold text-muted">
                    {department.hod.contact.phone}
                  </small>
                </section>

                <section className="py-0">
                  <p className="mb-0 fw-semibold">Programs</p>
                  <ul>
                    {department.programs.map((program, index) => (
                      <li key={`${department.slug}_program_${index}`}>
                        <small>{program.name}</small>
                      </li>
                    ))}
                  </ul>
                </section>
              </div>
            </div>
          ))}
        </section>
      </section>
    </>
  );
}

export default FacultyAbout;
