import Index from "../pages/Index";
import NotFound from "../pages/NotFound";

import Resources from "../pages/resources/Resources";
import ResourcesAdvert from "../pages/resources/Advert";
import ResourcesAffiliations from "../pages/resources/Affiliations";

import About from "../pages/about/About";
import AboutVice from "../pages/about/VCPrincipal";
import AboutChancellor from "../pages/about/Chancellor";
import AboutExecutive from "../pages/about/ExecutiveManagement";

import Admission from "../pages/admission/Admission";
import AdmissionUndergraduate from "../pages/admission/Undergraduate";
import AdmissionRequirement from "../pages/admission/AdmissionRequirements";
import AdmissionPaymentDetails from "../pages/admission/AdmissionPaymentDetails";

import Faculty from "../pages/faculty/Faculty";
import FacultyDean from "../pages/faculty/FacultyDean";
import FacultyAbout from "../pages/faculty/FacultyAbout";
import FacultyDetails from "../pages/faculty/FacultyDetails";
import FacultyDepartments from "../pages/faculty/FacultyDepartments";
import FacultyDepartmentDetails from "../pages/faculty/FacultyDepartmentDetails";

import Postgraduate from "../pages/postgraduate/Postgraduate";
import PostgraduateAbout from "../pages/postgraduate/PostgraduateAbout";
import PostgraduateOffice from "../pages/postgraduate/PostgraduateOffice";
import PostgraduatePayment from "../pages/postgraduate/PostgraduatePayment";
import PostgraduateAdmission from "../pages/postgraduate/PostgraduateAdmission";

import NewsMedia from "../pages/news/NewsMedia";
import NewsDetails from "../pages/news/NewsDetails";
import NewsMediaNews from "../pages/news/NewsMediaNews";

import Contact from "../pages/contact/Contact";
import ContactCampuses from "../pages/contact/ContactCampuses";
import ContactForm from "../pages/contact/ContactForm";

const AboutPages = {
  About,
  AboutVice,
  AboutChancellor,
  AboutExecutive,
};

const AdmissionPages = {
  Admission,
  AdmissionRequirement,
  AdmissionUndergraduate,
  AdmissionPaymentDetails,
};

const FacultyPages = {
  Faculty,
  FacultyDean,
  FacultyAbout,
  FacultyDetails,
  FacultyDepartments,
  FacultyDepartmentDetails,
};

const PostgraduatePages = {
  Postgraduate,
  PostgraduateOffice,
  PostgraduateAbout,
  PostgraduatePayment,
  PostgraduateAdmission,
};

const NewsMediaPages = {
  NewsMedia,
  NewsDetails,
  NewsMediaNews,
};

const ContactPages = {
  Contact,
  ContactCampuses,
  Form: ContactForm,
};

const ResourcesPages = {
  Resources,
  ResourcesAdvert,
  ResourcesAffiliations,
};

const Pages = {
  Index,
  Contact,
  NotFound,
  ...AboutPages,
  ...ContactPages,
  ...FacultyPages,
  ...AdmissionPages,
  ...NewsMediaPages,
  ...ResourcesPages,
  ...PostgraduatePages,
};

export default Pages;
