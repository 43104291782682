import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Data } from "../../assets/statics";
import { getParagraphs } from "../../assets/utils";

function Chancellor() {
  const { chancellor } = Data.Management;

  return (
    <>
      <h5 className="fw-bolder text-muted">Chancellor</h5>

      <Row className="mt-3">
        <Col md={3}>
          <Image
            fluid
            thumbnail
            src={`/images/executive/${
              chancellor.photo?.length > 0 ? chancellor.photo : "avatar.jpg"
            }`}
            alt={`photo_chancellor`}
          />
        </Col>

        <Col md>
          <p className="fs-4 fw-bold mb-0">{chancellor.name}</p>
          <Row className="mb-3 gy-2">
            <Col sm>
              <small className="fw-semibold">Phone</small>
              {getParagraphs(chancellor.contact?.phone)}
            </Col>

            <Col sm>
              <small className="fw-semibold">Email</small>
              {getParagraphs(chancellor.contact?.email)}
            </Col>
          </Row>

          <p className="text-justify">
            <span className="fw-bold text-muted">Bio</span>
            <br />
            {chancellor.bio}
          </p>
        </Col>
      </Row>
    </>
  );
}

export default Chancellor;
