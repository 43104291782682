import React from "react";
import { Accordion } from "react-bootstrap";

const args = {
  data: {},
};
function Resources(props = { ...args }) {
  props = { ...args, ...props };

  console.log(props);

  return (
    <>
      <h6 className="mb-3">
        The Ernest Bai Koroma University of Science and Technology Affiliations.
      </h6>
      <p className="mb-1">
        <em>
          The affiliated institutions and their programs as approved by the
          Tertiary Education Commission (TEC) are as follows;
        </em>
      </p>

      <Accordion defaultActiveKey={0} className="mb-4">
        {props.data.map((affiliate, index) => (
          <Accordion.Item
            key={`institution_${index}`}
            eventKey={index}
            className="rounded-0"
          >
            <Accordion.Header>
              <span className="fw-bold text-uppercase text-muted">
                {affiliate.institution}
              </span>
            </Accordion.Header>

            <Accordion.Body>
              <div>
                <strong>Programs</strong>
                <ol type="i">
                  {affiliate.programs.map((program, idx) => (
                    <li key={`institution_${index}_program_${idx}`}>
                      {program.name}

                      {program.options && (
                        <ul>
                          {program.options.map((option, i) => (
                            <li
                              key={`institution_${index}_program_${idx}_option_${i}`}
                            >
                              {option.name}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ol>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </>
  );
}

export default Resources;
