import React, { useMemo } from "react";
import { Card, Col, Image, NavLink, Row, Stack } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

const args = {
  data: [],
};

function NewsDetails(props = { ...args }) {
  props = { ...args, ...props };

  const params = useParams();
  const news = useMemo(() => {
    return props.data.find((news) => news.slug === params.slug) ?? {};
  }, [params.slug, props.data]);

  return (
    <>
      <Helmet>
        <meta name="description" content={news?.extract} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={news?.headline} />
        <meta property="og:description" content={news?.extract} />
        <meta name="twitter:creator" content={news?.publishedBy?.name} />
        <meta name="twitter:card" content="article" />
        <meta name="twitter:title" content={news?.headline} />
        <meta name="twitter:description" content={news?.extract} />
      </Helmet>
      <Row direction="horizontal" gap={2} className="gy-4">
        <Col lg>
          <Stack gap={4}>
            <div className="title">
              <h4 className="fw-bolder">{news?.headline}</h4>
              <p className="fw-semibold m-0">{news?.extract}</p>
            </div>

            {news?.legend && (
              <Image
                fluid
                src={`/images/news/${news?.legend}`}
                alt={news?.slug}
                className="block"
              />
            )}

            <div
              dangerouslySetInnerHTML={{ __html: news?.content }}
              className="text-justify"
            ></div>

            {news.publishedAt && (
              <author className="fw-bold">
                <em>
                  By: {news.publishedBy.name} |{" "}
                  {new Date(news.publishedAt).toDateString()}
                </em>
              </author>
            )}
          </Stack>
        </Col>

        <Col lg={3}>
          <Card>
            <Card.Header>
              <p className="fw-bold text-center text-muted text-uppercase m-0">
                Other News
              </p>
            </Card.Header>

            <Card.Body className="p-2">
              <Stack gap={1}>
                {props.data
                  .filter((n) => n.slug !== news.slug)
                  .slice(0, 4)
                  .map((_news, index) => (
                    <NavLink
                      key={_news.slug}
                      href={`/news-and-media/news/${_news.slug}`}
                      className={`hover-color hover-underline ${
                        index < props.data.length - 2 && " border-bottom pb-2"
                      }`}
                    >
                      <small>{_news.headline}</small>
                    </NavLink>
                  ))}
              </Stack>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default NewsDetails;
