import React from "react";
import { BanksDetails } from "../../components/BankDetails";

function PostgraduatePayment() {
  return (
    <>
      <h5 className="fw-bolder text-muted">Payment and Banking Details</h5>

      <section id="application-fee" className="py-0">
        <p className="fw-bold mb-1">
          <em>Application fee Payment</em>
        </p>

        <BanksDetails slug="postgraduate" />
      </section>

      <section id="application-fee" className="py-0">
        <p className="fw-bold mb-1">
          <em>Fee Payment</em>
        </p>

        <BanksDetails />
      </section>
    </>
  );
}

export default PostgraduatePayment;
