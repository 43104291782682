import React from "react";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { GoLocation } from "react-icons/go";
import { Col, Container, NavLink, Row, Stack } from "react-bootstrap";
import { Data } from "../assets/statics";
import { GetLocationUrl } from "./Map";

export const Footer = () => {
  return (
    <>
      <div className="text-bg-light py-4">
        <Container>
          <Row className="gy-4">
            <Col md={8}>
              <h4 className="fw-bold text-uppercase mb-3">Visit Us</h4>
              <Row className="gy-3">
                {Data.Places.map((place, index) => (
                  <Col key={`place_${index}`} className="col-6 min-w-fit">
                    <Stack
                      direction="horizontal"
                      className="align-items-start"
                      gap={2}
                    >
                      <GoLocation className="mt-1 fs-4" />
                      <Stack className="justify-content-start">
                        {place?.slug ? (
                          <GetLocationUrl
                            slug={place.slug}
                            className="m-0 fw-bold text-primary"
                          >
                            {place.name}
                          </GetLocationUrl>
                        ) : (
                          <span className="m-0 fw-bold text-primary">
                            {place.name}
                          </span>
                        )}
                        <small className="text-truncate">{place.address}</small>
                        <NavLink
                          href={`tel: ${place.phone}`}
                          className="max-w-fit hover-underline"
                        >
                          <small className="text-truncate">{place.phone}</small>
                        </NavLink>
                      </Stack>
                    </Stack>
                  </Col>
                ))}
              </Row>
            </Col>

            <Col sm>
              <h5 className="mb-3 fw-bold text-uppercase">Support</h5>
              <Row className="gy-3">
                <Col md={12} className="col-6">
                  <small className="fw-bold">Call:</small>
                  <NavLink href="tel: 23276780302">(232) 76 780-302</NavLink>
                </Col>

                <Col md={12} className="col-6">
                  <small className="fw-bold">WhatsApp:</small>
                  <NavLink
                    target="_blank"
                    rel="no-referrer"
                    href="https://wa.me/+23276780302"
                  >
                    (232) 76 780-302
                  </NavLink>
                </Col>

                <Col md={12} className="col-6">
                  <small className="fw-bold">Email</small>
                  <NavLink href="mailto: support@ebkustsl.edu.sl">
                    support@ebkustsl.edu.sl
                  </NavLink>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="text-bg-dark py-3">
        <Container>
          <Row className="align-items-center gy-2">
            <Col md={9} className="order-1 order-md-0">
              <p className="m-0 text-white-50 text-center text-md-start">
                &copy; {new Date().getFullYear()}{" "}
                <span className="fw-semibold">
                  <abbr
                    className="d-inline d-sm-none fw-bold text-light"
                    title="Ernest Bai Koroma University of Science and Technology"
                  >
                    EBKUST
                  </abbr>
                  <span className="d-none d-sm-inline">
                    Ernest Bai Koroma University of Science and Technology
                  </span>
                </span>
              </p>
            </Col>
            <Col md className="text-end order-0 order-md-1">
              <Stack
                gap={3}
                direction="horizontal"
                className="justify-content-center justify-content-md-end"
              >
                <NavLink
                  href="https://www.facebook.com/ebkustsl"
                  target="_blank"
                  rel="no-referrer"
                  className="fs-5 hover-color"
                >
                  <FaFacebook />
                </NavLink>

                <NavLink
                  href="https://twitter.com/ebkustsl"
                  target="_blank"
                  rel="no-referrer"
                  className="fs-5 hover-color"
                >
                  <FaTwitter />
                </NavLink>

                <NavLink
                  href="https://www.instagram.com/ebkustsl"
                  target="_blank"
                  rel="no-referrer"
                  className="fs-5 hover-color"
                >
                  <FaInstagram />
                </NavLink>

                <NavLink
                  href="https://www.linkedin.com/company/ebkustsl"
                  target="_blank"
                  rel="no-referrer"
                  className="fs-5 hover-color"
                >
                  <FaLinkedin />
                </NavLink>
              </Stack>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
