import React from "react";
import { Footer, Header } from "../assets/components";
import { Outlet } from "react-router-dom";
import { Stack } from "react-bootstrap";
import useAdvert from "../hooks/advert.hook";

export default function Main() {
  const { hasContent, modal } = useAdvert();
  return (
    <>
      <Stack className="min-vh-100">
        <Header />

        <main className="flex-1">
          <Outlet />
        </main>

        <footer className="mt-auto">
          <Footer />
        </footer>
      </Stack>

      {hasContent && modal}
    </>
  );
}
