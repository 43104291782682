import React from "react";
import { Container, Image } from "react-bootstrap";
import { Images } from "../assets/statics";
import { Footer, Header } from "../assets/components";

function NotFound() {
  return (
    <>
      <Header />

      <Container className="text-center" style={{ padding: ".1rem 0" }}>
        <Image src={Images.NotFoundImage} fluid />
      </Container>

      <Footer />
    </>
  );
}

export default NotFound;
