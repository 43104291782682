import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Data } from "../assets/statics";
import { GetDropdowns } from "../assets/utils";
import { Link } from "react-router-dom";

export const MainNav = () => {
  function getContent(route) {
    if (route.children.length > 0) {
      return GetDropdowns(route, "down", route);
    }

    return (
      <Link to={route?.href || route.url} key={route.slug} className="nav-link">
        {route.title}
      </Link>
    );
  }

  return (
    <Navbar
      bg="primary"
      expand="md"
      variant="dark"
      sticky="top"
      // collapseOnSelect={true}
      className="py-2 py-md-0 shadow align-items-center"
    >
      <Container className="align-items-center">
        <span className="fw-bold d-flex d-md-none text-white h5">
          Site Navigation
        </span>
        <Navbar.Toggle aria-controls="main-nav" className="border text-white" />

        <Navbar.Collapse id="main-nav" className="mt-1 mt-md-0 pt-2 pt-md-0">
          {/* For small screen */}
          <Nav className="d-flex d-md-none">
            {Data.Routes.map((route) => (
              <Nav.Link
                key={route.slug}
                href={route.url}
                className="px-3"
                // reloadDocument={true}
              >
                {route.title}
              </Nav.Link>
            ))}
          </Nav>

          {/* For wide screens */}
          <Nav className="main-nav d-none d-md-flex justify-content-center w-100">
            {Data.Routes.map((route) =>
              getContent({
                ...route,
                children:
                  route.children.length > 0
                    ? [{ ...route, children: [] }, ...route.children]
                    : [],
              })
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
