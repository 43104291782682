import React from "react";
import { Data } from "../../assets/statics";
import { Card, Col, Row, Stack } from "react-bootstrap";
import { LinkedDetailCard, Map } from "../../assets/components";
import { GetLocationUrl } from "../../components/Map";

function Contact() {
  return (
    <>
      <Row className="gy-4 mb-4">
        {Data.Locations.map((contact, index) => (
          <Col className="col-12" key={`location_${index}`}>
            <Card>
              <Card.Header className="bg-white border-light text-secondary">
                <Stack
                  direction="horizontal"
                  className="justify-content-between align-items-center"
                >
                  <h5 className="mb-0">{contact.location}</h5>
                  <GetLocationUrl
                    className="text-secondary"
                    slug={contact.slug}
                  >
                    <strong>VISIT</strong>
                  </GetLocationUrl>
                </Stack>
              </Card.Header>
              <Card.Body>
                <Row className="gy-3">
                  <Col lg>
                    <Map contact={contact} />
                  </Col>
                  <Col>
                    <Stack gap={2}>
                      {contact.list?.map((item, key) => (
                        <Stack
                          key={`location_${index}_contact_${key}`}
                          className={
                            key < contact.list.length - 1 &&
                            "pb-2 border-bottom"
                          }
                        >
                          <p className="fs-6 fw-bold mb-0">{item.position}</p>
                          <p className="mb-0">
                            <small>Phone: </small>
                            <a
                              href={`tel: ${item.phone}`}
                              className="fw-semibold"
                            >
                              {item.phone}
                            </a>
                          </p>
                          <span>
                            <small>Email: </small>
                            <a
                              href={`mailto: ${item.email}`}
                              className="fw-semibold"
                            >
                              {item.email}
                            </a>
                          </span>
                        </Stack>
                      ))}
                    </Stack>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      {[{ title: "Have a question? Let's talk.", href: "contact-form" }].map(
        (detail, index) => (
          <LinkedDetailCard
            key={index}
            title={detail.title}
            className="mb-3"
            href={`/contact-us/${detail.href}`}
          />
        )
      )}
    </>
  );
}

export default Contact;
