import React, { useMemo } from "react";
import { Breadcrumb as NavBreadCrumb } from "react-bootstrap";
import { SearchRoutes, toCapitals } from "../assets/utils";
import { Data } from "../assets/statics";

const args = {
  path: [],
};

export const Breadcrumb = (props = { ...args }) => {
  props = { ...args, ...props };
  const path = useMemo(() => {
    const routes = props.path.map((p, i) => ({
      title:
        SearchRoutes(Data.Routes, "slug", p)?.title ||
        Data.Departments.find((d) => d.slug === p)?.name ||
        toCapitals(p),
      url: i < 1 ? `/${p}` : `/${props.path.slice(0, i + 1).join("/")}`,
    }));

    return [
      {
        title: "Home",
        url: "/",
      },
      ...routes,
    ];
  }, [props.path]);

  return (
    <NavBreadCrumb className="px-3 py-2 border rounded bg-light">
      {path.map((link, index) => (
        <NavBreadCrumb.Item
          href={link.url}
          key={`breadcrumb_${index}`}
          active={index === path.length - 1}
          className="hover-underline"
        >
          {link?.title}
        </NavBreadCrumb.Item>
      ))}
    </NavBreadCrumb>
  );
};
