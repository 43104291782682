import React from "react";
import { TopNav } from "./TopNav";
import { MainNav } from "./MainNav";

export const Header = () => {
  return (
    <>
      <TopNav />

      <MainNav />
    </>
  );
};
