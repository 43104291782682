import React from "react";
import { LinkedDetailCard } from "../../assets/components";
import { portalUrl } from "../../assets/utils";
import { BiCloudDownload } from "react-icons/bi";
import adverts from "../../static/adverts.json";

function Admission() {
  const advert = adverts.find((advert) => advert.isCurrent);

  return (
    <>
      <section className="py-0 mb-3" id="admissions">
        <p className="text-justify text-muted m-1 fw-semibold">
          <em>
            The 2024/2025 Application for admission to the university is open to
            all qualified
          </em>
        </p>

        <p>
          We welcome all qualified candidates to{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href={`${portalUrl}/studentcheck.aspx`}
          >
            <strong>
              <em>apply</em>
            </strong>
          </a>{" "}
          online through our university's online portal. Don't miss this
          opportunity to join our prestigious institution and embark on a
          journey of knowledge and growth.
        </p>

        <div className="alert alert-info alert-dismissible">
          <button data-bs-dismiss="alert" className="btn btn-close"></button>
          <p>
            Our courses are designed to meet the needs of the modern world and
            to prepare students for the challenges of the future.
          </p>
          <a
            target="_black"
            rel="no-referrer"
            href={advert?.courses}
            className="btn btn-success"
          >
            <span>Download course brochure</span>

            <BiCloudDownload size={26} className="ms-2" />
          </a>
        </div>
      </section>

      <h5 className="text-primary">Learn more..</h5>
      {[
        {
          title: "Admission Requirements",
          href: "admission-requirement",
        },
        {
          title: "Payment and Banking Details",
          href: "payment-and-banking-details",
        },
      ].map((detail, index) => (
        <LinkedDetailCard
          key={index}
          title={detail.title}
          className="mb-3"
          href={`/admissions/${detail.href}`}
        />
      ))}
    </>
  );
}

export default Admission;
