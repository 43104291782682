import { Link } from "react-router-dom";

export const portalUrl = "https://portal.ebkustsl.edu.sl";

export const UCFirst = (str = "") => {
  if (!str?.charAt) return str;

  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function flatten(array = []) {
  array.forEach((a) => {
    if (a.children.length > 0) {
      array = [...array, ...flatten(a.children)];
    }
  });

  return array;
}

export function SearchRoutes(routes = [], key = "", value = "") {
  const res = flatten(routes).find((r) => r[key] === value);

  return res;
}

export function NumberToArray(num) {
  return [...Array(num).keys()];
}

export function GetDropdowns(
  route,
  drop = "down",
  parent = null,
  className = ""
) {
  return (
    <div key={route.slug} className={`nav-item drop${drop}`}>
      <Link
        to={`#${route.slug}`}
        className={`${
          drop === "down" ? "nav-link" : "dropdown-item"
        } dropdown-toggle ${className}`}
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span>{route.title}</span>
      </Link>

      <ul className="dropdown-menu" id={route.slug}>
        {route?.children.map((child) =>
          child?.children?.length > 0 ? (
            GetDropdowns(child, "end", route)
          ) : (
            <li key={child.slug}>
              <Link
                to={
                  child?.href ||
                  (child.url !== route.url ? parent.url : "") +
                    (parent.url !== route.url ? route.url : "") +
                    child.url
                }
                className="dropdown-item"
              >
                <span>{child.title}</span>
              </Link>
            </li>
          )
        )}
      </ul>
    </div>
  );
}

export function ArrayGet(array, index = -1) {
  return array?.[index > -1 ? index : array?.length + index];
}

export function toCapitals(str, separator = "-") {
  return str
    .split(separator)
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(" ");
}

export function getParagraphs(string, separator = "/") {
  return string.split(separator).map((str, i) => (
    <span className="m-0 d-block" key={`paragraph_${i}`}>
      {str}
    </span>
  ));
}

export function toWords(number) {
  const words = [
    "zero",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
    "twenty",
  ];
  return words[number];
}
