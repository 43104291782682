import { GoogleMap, LoadScript } from "@react-google-maps/api";
import React, { useCallback, useMemo } from "react";
import { Data } from "../assets/statics";

const Map = ({ contact = {} }) => {
  const polygons = useMemo(() => {
    return Data.GeoData.features
      .filter((f) => f.geometry.type === "Polygon")
      .map((feature) => {
        return {
          slug: feature.properties.Name.toLocaleLowerCase(),
          coordinates: feature.geometry.coordinates[0].map((c) => ({
            lat: c[1],
            lng: c[0],
          })),
        };
      });
  }, []);

  function getBounds() {
    const bounds = new window.google.maps.LatLngBounds();

    polygons
      .find((p) => p.slug === contact.slug)
      .coordinates.forEach((coordinate) => {
        bounds.extend(coordinate);
      });
    return bounds;
  }

  function onLoad(map) {
    map.fitBounds(getBounds());
    const data = new window.google.maps.Data();
    data.addGeoJson(Data.GeoData);
    data.setStyle({
      fillColor: "white",
      strokeColor: "gold",
      strokeWeight: 3,
    });

    data.addListener("mouseover", (event) => {
      data.revertStyle();
      data.overrideStyle(event.feature, { fillColor: "gold" });
    });

    data.addListener("mouseout", (event) => {
      data.revertStyle();
    });

    data.addListener("click", (event) => {
      console.log(event.feature.getProperty("Name"));
    });

    data.setMap(map);
    //map.data.addGeoJson(Data.GeoData);
  }

  return (
    <LoadScript
      region="sl"
      language="en"
      googleMapsApiKey={process.env.REACT_APP_MAPS_API_KEY}
      libraries={["drawing", "visualization", "geometry"]}
    >
      <GoogleMap
        options={{
          disableDefaultUI: true,
          zoomControl: false,
          fullscreenControl: false,
          mapTypeId: "satellite",
          keyboardShortcuts: false,
        }}
        onLoad={onLoad}
        mapContainerStyle={{
          width: "100%",
          height: "100%",
          minHeight: "320px",
        }}
      />
    </LoadScript>
  );
};

const args = {
  slug: "",
  title: "",
  target: "_blank",
  className: "",
};

export function GetLocationUrl(props = { ...args }) {
  props = { ...args, ...props };

  const points = useMemo(() => {
    return Data.GeoData.features
      .filter((f) => f.geometry.type === "Point")
      .map((feature) => {
        return {
          slug: feature.properties.Name.split(" ")[0].toLocaleLowerCase(),
          coordinates: feature.geometry.coordinates
            .slice(0, 2)
            .sort((a, b) => b - a),
        };
      });
  }, []);

  const getPoints = useCallback(() => {
    const point = points.find((p) => p.slug === props.slug);

    return point.coordinates;
  }, [points, props.slug]);

  const href = useMemo(() => {
    return `https://www.google.com/maps/search/?api=1&query=${getPoints().join(
      ","
    )}&ll=${getPoints().join(",")}&t=k&z=12&hl=en&view=satellite`;
  }, [getPoints]);

  return (
    <a
      target={props.target}
      rel="noreferrer"
      className={`hover-underline ${props.className}`}
      href={href}
    >
      {props.title.length > 0 ? props.title : props.children}
    </a>
  );
}

export default Map;
