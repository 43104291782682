import React from "react";
import { Data } from "../assets/statics";

export const BanksDetails = ({ children, slug = "postgraduate" }) => {
  const { banks } = Data.Programs?.[slug];

  return (
    <ul>
      {banks.map((bank, key) => (
        <li key={`bank_${key}`} className="mb-2">
          <p className="mb-0">
            <small className="text-muted">
              <strong>Bank Name:</strong>
            </small>{" "}
            <span>{bank.name}</span>
          </p>
          <p className="mb-0">
            <small className="text-muted">
              <strong>Account Name:</strong>
            </small>{" "}
            <span>{bank.acname}</span>
          </p>
          <p className="mb-0">
            <small className="text-muted">
              <strong>Account Number:</strong>
            </small>{" "}
            <span>{bank.acno}</span>
          </p>
          {bank?.bban && (
            <p className="mb-0">
              <small className="text-muted">
                <strong>BBAN:</strong>
              </small>{" "}
              <span>{bank.bban}</span>
            </p>
          )}
        </li>
      ))}
      {children && <li>{children}</li>}
    </ul>
  );
};
