import React, { useState } from "react";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";

function ContactForm() {
  const [validated, setValidated] = useState(false);

  function handleSubmit(event) {
    event.preventDefault();

    if (event.currentTarget.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);

      return;
    }

    console.log(event);
  }

  return (
    <>
      <div className="mb-4">
        <h5 className="mb-0">Have a question?</h5>
        <p className="mb-0">
          <em>
            Send us a message and we'll get back to you as soon as possible
          </em>
        </p>
      </div>

      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col md>
            <Form.FloatingLabel label="Name" className="mb-3">
              <Form.Control
                type="text"
                placeholder="Name"
                required
                name="name"
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid name.
              </Form.Control.Feedback>
            </Form.FloatingLabel>
          </Col>
          <Col md>
            <Form.FloatingLabel label="Email" className="mb-3">
              <Form.Control
                type="email"
                placeholder="Email"
                required
                name="email"
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid email.
              </Form.Control.Feedback>
            </Form.FloatingLabel>
          </Col>
        </Row>
        <Form.FloatingLabel label="Message" className="mb-3">
          <Form.Control
            as="textarea"
            style={{ height: "10rem" }}
            placeholder="Message"
            name="message"
            required
          />
          <Form.Control.Feedback type="invalid">
            Please provide a message to send.
          </Form.Control.Feedback>
        </Form.FloatingLabel>

        <Stack direction="horizontal" gap={3}>
          <Button variant="secondary" type="submit">
            <span className="lead">Send Message</span>
          </Button>

          <div className="text-success fw-semibold"></div>
        </Stack>
      </Form>
    </>
  );
}

export default ContactForm;
