import React, { useMemo } from "react";
import { Col, Image, Row } from "react-bootstrap";
import Static from "../../assets/statics";
import { LinkedDetailCard } from "../../assets/components";

const args = {
  faculty: {},
};

function FacultyDetails(props = { ...args }) {
  props = { ...args, ...props };

  const departments = useMemo(() => {
    return Static.Departments.filter((d) => d.faculty === props.faculty?.slug);
  }, [props.faculty]);

  return (
    <>
      <section className="py-0 mb-3" id="about-faculty">
        <h4 className="text-muted">
          About the Faculty of {props.faculty?.name}
        </h4>
        <p
          className="text-justify m-1"
          style={{
            overflow: "hidden",
            lineHeight: "1.5rem",
            maxHeight: "15rem",
          }}
        >
          {props.faculty?.description}
        </p>
        <a
          href={`/faculty/${props.faculty?.slug}/about`}
          className="text-info fw-semibold hover-underline"
        >
          Read more...
        </a>
      </section>

      <section className="p-0 mb-3" id="deanery-faculty">
        <a
          href={`/faculty/${props.faculty?.slug}/dean`}
          className="fs-4 text-primary hover-underline h4"
        >
          Office of the Dean
        </a>

        <Row className="mt-3">
          <Col md={3}>
            <Image
              src={`/images/deans/${props.faculty?.dean?.photo}`}
              alt={`photo_dean_${props.faculty?.slug}`}
              className="img-fluid img-thumbnail"
            />
          </Col>
          <Col>
            <p className="fs-4 fw-bold text-muted mb-0">
              {props.faculty?.dean?.name}
            </p>
            <p
              className="text-justify m-1"
              style={{
                overflow: "hidden",
                lineHeight: "1.5rem",
                maxHeight: "7.5rem",
              }}
            >
              {props.faculty?.dean?.bio}
            </p>
            <a
              href={`/faculty/${props.faculty?.slug}/dean`}
              className="text-info fw-semibold hover-underline"
            >
              Read more...
            </a>
          </Col>
        </Row>
      </section>

      <section className="p-0">
        <p className="mb-3">
          <a
            href={`/faculty/${props.faculty?.slug}/departments`}
            className="fs-4 text-primary hover-underline h4"
          >
            Departments
          </a>
        </p>

        <Row className="gy-2">
          {departments?.map &&
            departments?.map((department) => (
              <Col xl={6} key={department.slug}>
                <LinkedDetailCard
                  title={department.name}
                  color={props.faculty?.color}
                  href={`/faculty/${props.faculty?.slug}/departments/${department.slug}`}
                />
              </Col>
            ))}
        </Row>
      </section>
    </>
  );
}

export default FacultyDetails;
