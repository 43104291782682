import React from "react";
import { UCFirst } from "../../assets/utils";
import { Data } from "../../assets/statics";

function PostgraduateAdmission() {
  const { requirements } = Data.Programs.postgraduate;

  return (
    <>
      <h5 className="fw-bolder text-muted">Admission Requirements</h5>

      {Object.keys(requirements).map((slug, idx) => (
        <div key={idx} className="mb-3">
          <p className="mb-1 fw-bold">
            <em>
              {slug === "diploma" && "Postgraduate"} {UCFirst(slug)}
            </em>
          </p>

          <ul>
            {requirements?.[slug].map((req, idx) => (
              <li key={`req_${idx}`}>{req}</li>
            ))}
          </ul>
        </div>
      ))}
    </>
  );
}

export default PostgraduateAdmission;
