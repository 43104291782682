import React from "react";
import { Data } from "../../assets/statics";
import { LinkedDetailCard } from "../../assets/components";
import { Col, Row } from "react-bootstrap";

function About() {
  return (
    <>
      <section className="text-justify p-0">
        <p className="fw-semibold">
          The Ernest Bai Koroma University of Science and Technology, better
          known as{" "}
          <abbr title="Ernest Bai Koroma University of Science and TEchnology">
            <strong>
              <small>EBKUST</small>
            </strong>
          </abbr>{" "}
          is one of the six public universities in Sierra Leone.
        </p>

        <section className="p-0">
          <p className="m-0">
            The university does not have a long history but the constituent
            institutions do have thriving history and purpose built campuses to
            leverage though with a huge need for rehabilitation and upscaling:
          </p>
          <ul>
            <li>
              The former women’s college and later Portloko staffs college that
              now constitute the Portloko campus, was the only tertiary
              institution for women that trained and certified authentic and
              diligent female staffs who have stood the test of time in the
              teaching vocation.
            </li>
            <li>
              The now Makeni campus was first established as a catholic college,
              the St. Augustine’s college, and later became the Makeni staff’s,
              which then morphed into the Northern polytechnic. Here morality
              was the key for all graduates of the college. The impact created
              by staffs from the college was of no mean feat. The former
              veterinary institute at Teko, which is another location of the
              Makeni campus, was the headquarter for veterinary research in the
              subregion. This now sadly stands in ruins.
            </li>
            <li>
              The former Islamic college that now houses the secretariat, and
              the former government technical institute, otherwise referred to
              as the
              <em>trade center</em> in Magburaka, now the institute of technical
              and vocational education and training in the faculty of
              engineering were the pride for Islamic and technical education in
              the northern region. These were all established to provide a
              holistic education for corpus, anima et spirit (body, mind, and
              spirit)
            </li>
          </ul>
        </section>

        <section className="p-0">
          <p className="m-0">
            Upon cabinet approval, a bill was sent to parliament in June 2014,
            amending the Universities Act 2005 to establish the Ernest Bai
            Koroma University of Science and Technology; and another (The
            Polytechnics (Amendment) Act, 2014) to “delete the Makeni
            Polytechnic and Portloko polytechnic from the Schedule of the
            Polytechnic Act 2001 because theses institutions have been upgraded
            to university status under the (Amendment) Act 2014”. On the 17
            <sup>th</sup> day of September 2014, this was signed into law; the
            Universities (Amendment) Act 2014 establishing the Ernest Bai Koroma
            University of Science and Technology, comprising;
          </p>
          <ol>
            <li>
              “The Magburaka University College” incorporating the Islamic
              College, Magburaka and the Government Technical Institute,
              Magburaka;
            </li>
            <li>
              “The Makeni University College” incorporating the Makeni
              Polytechnic and Teko Veterinary Institute; and{" "}
            </li>
            <li>“The Portloko University College”</li>
            <li>
              Any other institution that may, on the advice on the Tertiary
              Education Commission, be constituted as Campus by Statutory
              Instrument made by the Minister.
            </li>
          </ol>
          <p className="m-0">
            The Secretariat of the Ernest Bai Koroma University of Science and
            Technology, in the heart of the Tonkolili District, with campuses in
            the three northern-district-headquarter towns of Makeni (in
            Bombali), Portloko (in Portloko) and Magburaka (in Tonkolili)
          </p>
        </section>
      </section>

      <section className="py-4 text-justify">
        <h5 className="fw-bold text-muted">
          <u>Our Mission</u>
        </h5>
        <p className="m-0">
          Our strategic mission focuses on the generation and acquisition of
          needs-specific knowledge determined by a global economy. We are
          committed to the development of human capital for the advancement of
          the nation and the world at large.
        </p>
        <p className="m-0">
          Though not limited to this there are seven strategic pillars that
          guide the operations of the university. These include ;
        </p>
        <ul>
          <li>
            <strong>
              <em>
                Widening access and participation-providing for financially
                needy students from poor rural homes
              </em>
            </strong>
          </li>
          <li>
            <strong>
              <em>Provision of relevant and high-quality academic programs.</em>
            </strong>
          </li>
          <li>
            <strong>
              <em>Intensifying performance in research and innovation</em>
            </strong>
          </li>
          <li>
            <strong>
              <em>Strengthening capacity for excellence in delivery</em>
            </strong>
          </li>
          <li>
            <strong>
              <em>
                Integrity, transparency and accountability in all undertakings
              </em>
            </strong>
          </li>
          <li>
            <strong>
              <em>Character development and love of country</em>
            </strong>
          </li>
        </ul>
      </section>

      <section className="p-0 pb-4 text-justify">
        <h5 className="fw-bold text-muted">
          <u>Our Strategic Goals</u>
        </h5>
        <p className="m-0">
          <abbr title="Ernest Bai Koroma University fo Science and Technology">
            EBKUST
          </abbr>{" "}
          aspires towards the following strategic goals.
        </p>
        <ul>
          <li>
            Build a 21st century university that responds to contemporary and
            emerging national and global needs
          </li>
          <li>
            Promote excellence in teaching, research and innovative thinking
          </li>
          <li>Develop intellectual capital for national development</li>
          <li>
            Serve as a linchpin for the engagement of communities, the private
            sector and development partners in the national development process
          </li>
          <li>
            Offer an opportunity to all student for their preferred
            self-actualization
          </li>
          <li>Contribute to the development of a national culture of change</li>
        </ul>
      </section>

      <div className="d-md-none">
        <h5 className="fw-bold mb-3">Lean more about</h5>
        <Row className="gy-3 ">
          {Data.Routes.find((r) => r.slug === "about").children.map(
            (detail, index) => (
              <Col lg key={index} className="min-w-fit">
                <LinkedDetailCard
                  title={detail.title}
                  className="mb-3"
                  href={`/about${detail.url}`}
                />
              </Col>
            )
          )}
        </Row>
      </div>
    </>
  );
}

export default About;
