import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import Static from "../../assets/statics";
import { LinkedDetailCard } from "../../assets/components";

const args = {
  faculty: {},
};

function FacultyDepartments(props = { ...args }) {
  props = { ...args, ...props };

  const departments = useMemo(() => {
    return Static.Departments.filter((d) => d.faculty === props.faculty?.slug);
  }, [props.faculty]);

  return (
    <>
      <h4 className="fw-bold mb-4">
        Departments of Faculty of {props.faculty?.name}
      </h4>

      <Row className="gy-3">
        {departments?.map &&
          departments?.map((department) => (
            <Col md={6} key={department.slug}>
              <LinkedDetailCard
                title={department.name}
                color={props.faculty?.color}
                href={`/faculty/${props.faculty?.slug}/departments/${department.slug}`}
              />
            </Col>
          ))}
      </Row>
    </>
  );
}

export default FacultyDepartments;
