import React, { useState } from "react";
import {
  Card,
  Col,
  FormControl,
  Image,
  NavLink,
  Row,
  Stack,
} from "react-bootstrap";

const args = {
  news: [],
};
function NewsMediaNews(props = { ...args }) {
  props = { ...args, ...props };
  const [news, setNews] = useState(props.news);
  const [searchTerm, setSearchTerm] = useState("");

  function handleSearching({ target: { value } }) {
    setNews(
      props.news.filter((news) =>
        news.headline.toLowerCase().includes(value.toLowerCase())
      )
    );

    setSearchTerm(value);
  }

  return (
    <Row
      direction="horizontal"
      className="justify-content-between align-items-start gy-4"
    >
      <Col lg className="order-1 order-lg-0">
        {searchTerm.length > 0 && (
          <p className="mb-2">
            Search result: <strong>{searchTerm}</strong>
          </p>
        )}

        <Stack gap={4}>
          {news.length > 0 ? (
            news.map((news, index) => {
              return NewsContent(news, ++index);
            })
          ) : (
            <h5 className="text-muted">Nothing found from search</h5>
          )}
        </Stack>
      </Col>

      <Col lg={2} className="order-0 order-lg-1">
        <FormControl
          onChange={handleSearching}
          type="search"
          placeholder="Search news...."
        />
      </Col>
    </Row>
  );
}

export function NewsContent(news, index, className = "") {
  return (
    <Row key={news.slug} className={`${className}`}>
      {index > 0 && (
        <Col className="col-12">
          <NavLink
            href={`/news-and-media/news/${news.slug}`}
            className="fw-bold text-primary fs-5 mb-0 mb-lg-2"
          >
            {news.headline}
          </NavLink>
          {news.publishedBy && (
            <p>
              <em>
                By: {news.publishedBy.name} |{" "}
                {new Date(news.publishedAt).toDateString()}
              </em>
            </p>
          )}
        </Col>
      )}

      <Col lg className="mb-3">
        <Image
          src={`/images/news/${news.legend}`}
          fluid
          thumbnail={index > 0}
        />
      </Col>
      <Col lg={index < 1 ? 7 : 8}>
        {index < 1 && (
          <Card.Header className="border-0 p-0">
            <h5 className="fw-bold">{news.headline}</h5>
          </Card.Header>
        )}
        <Card className="border-0">
          <Card.Body className="p-0">
            <p className="text-justify mb-2">{news.extract}</p>
            <NavLink
              href={`/news-and-media/news/${news.slug}`}
              className="font-semibold text-primary hover-underline max-w-fit text-truncate"
            >
              Read more
            </NavLink>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default NewsMediaNews;
