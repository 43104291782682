import React from "react";
import { LinkedDetailCard } from "../../assets/components";

function AdmissionRequirements() {
  return (
    <>
      <section className="py-0 mb-3" id="admissions">
        <h4 className="text-muted">Admission Requirements</h4>

        {/* <p className="text-justify text-muted m-1 fw-semibold">
          <em>
            Application dates for the 2023/2024 academic year will be announced
            soon.
          </em>
        </p> */}
      </section>

      <h5 className="text-primary">Quick Links</h5>
      {[
        {
          title: "Undergraduate",
          href: "admissions/admission-requirement/undergraduate",
        },
        {
          title: "Masters and Postgraduate Diploma",
          href: "postgraduate-school/admission-requirements",
        },
        {
          title: "PhD",
          href: "postgraduate-school/admission-requirements",
        },
      ].map((detail, index) => (
        <LinkedDetailCard
          key={index}
          title={detail.title}
          className="mb-3"
          href={`/${detail.href}`}
        />
      ))}
    </>
  );
}

export default AdmissionRequirements;
