import React from "react";
import { Data } from "../../assets/statics";
import { Col, Image, Row } from "react-bootstrap";

function PostgraduateOffice() {
  const { dean = {} } = Data.Programs.postgraduate;

  function getParagraphs(string, separator = "/") {
    return string?.split
      ? string.split(separator).map((str, i) => (
          <span className="m-0 d-block" key={`paragraph_${i}`}>
            {str}
          </span>
        ))
      : "";
  }

  return (
    <>
      <h5 className="fw-bolder text-muted">Office of the Dean</h5>

      <Row className="mt-3">
        <Col md={3}>
          <Image
            src={`/images/deans/${dean?.photo}`}
            alt="dean_postgraduate"
            className="img-fluid img-thumbnail"
          />
        </Col>

        <Col md>
          <p className="fs-4 fw-bold mb-0">{dean?.name}</p>
          <Row className="mb-3 gy-2">
            <Col sm>
              <small className="fw-semibold">Phone</small>
              {getParagraphs(dean?.contacts?.phone)}
            </Col>

            <Col sm>
              <small className="fw-semibold">Email</small>
              {getParagraphs(dean?.contacts?.email)}
            </Col>
          </Row>

          <p className="text-justify">
            <span className="fw-bold text-muted">Bio</span>
            <br />
            {dean?.bio}
          </p>

          <section className="text-justify py-0">
            <span className="fw-bold text-muted">Qualifications</span>
            <br />
            <ul>
              {dean?.qualification?.map((qua, idx) => (
                <li key={`qualification_${idx}`}>{qua}</li>
              ))}
            </ul>
          </section>
        </Col>
      </Row>
    </>
  );
}

export default PostgraduateOffice;
