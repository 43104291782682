import React, { useMemo } from "react";
import adverts from "../../static/adverts.json";

const args = {
  data: {},
};

function Advert(props = { ...args }) {
  props = { ...args, ...props };
  const advert = useMemo(() => adverts.find((ad) => ad.isCurrent), []);

  return <iframe title={advert.title} src={advert.url} height={620}></iframe>;
}

export default Advert;
