import React from "react";
import { Data } from "../../assets/statics";
import { Card, Col, NavLink, Row, Stack } from "react-bootstrap";
import { BiMailSend, BiPhoneCall } from "react-icons/bi";
import { FaWhatsapp } from "react-icons/fa";

function ExecutiveManagement() {
  return (
    <>
      <p className="lead fw-bold">Executive Management</p>
      <Row className="m-0 px-3 px-sm-0 gy-3">
        {Data.Management.executive.map((item, index) => (
          <Col sm={6} lg={4} key={index} className="mb-3">
            <Card className="text-center border-0 hover-shadow pb-3 h-100">
              <Card.Img
                variant="top"
                alt={`${item.designation}'s photo`}
                src={`/images/executive/${
                  item.photo?.length > 0 ? item.photo : "avatar.jpg"
                }`}
                className="rounded mx-auto"
              />
              <Card.Body>
                <Card.Title className="text-muted fw-bolder">
                  {item.name}
                </Card.Title>
                <Card.Subtitle className="mb-2">
                  {item.designation}
                </Card.Subtitle>
                {item?.campus && (
                  <Card.Text className="lead">{item.campus}</Card.Text>
                )}
              </Card.Body>
              <Card.Footer
                gap={3}
                as={Stack}
                direction="horizontal"
                className="justify-content-center align-items-center bg-transparent border-0"
              >
                <NavLink
                  href={`mailto: ${item.contact.email}`}
                  title={item.contact.email}
                  className="hover-color"
                >
                  <BiMailSend className="fs-3" />
                </NavLink>
                <NavLink
                  href={`tel: ${item.contact.phone}`}
                  title={item.contact.phone}
                  className="hover-color"
                >
                  <BiPhoneCall className="fs-4" />
                </NavLink>
                <NavLink
                  target="_blank"
                  rel="no-referrer"
                  href={`https://wa.me/${item.contact.phone}`}
                  title={`WhatsApp: ${item.contact.phone}`}
                  className="hover-color"
                >
                  <FaWhatsapp className="fs-4" />
                </NavLink>
              </Card.Footer>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
}

export default ExecutiveManagement;
