import { Route, Routes } from "react-router-dom";
import Layouts from "./assets/layouts";
import Static from "./assets/statics";
import Pages from "./assets/pages";
import { ArrayGet, UCFirst, toCapitals } from "./assets/utils";
import { useEffect } from "react";

function App() {
  function getPage(title, props, index = 0) {
    title = toCapitals(title, "-");
    const titleArr = title.split(" ");
    const Page = Pages?.[ArrayGet(titleArr, index)];

    if (Page) return <Page {...props} />;

    return <span>Not found</span>;
  }

  useEffect(() => {
    document.querySelectorAll("img").forEach((img) => {
      img.addEventListener("error", () => {
        img.src = "/images/avatar.jpg";
      });
    });
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Layouts.MainLayout />}>
        <Route index element={<Pages.Index />} />
        <Route path="about" element={<Layouts.PageLayout useParent={false} />}>
          <Route index element={<Pages.About />} />
          {Static.Routes.find((f) => f.slug === "about")?.children?.map(
            (sub) => (
              <Route
                key={sub.slug}
                path={sub.url.replace("/", "")}
                element={getPage(`About${UCFirst(sub.slug)}`, {
                  data: sub,
                })}
              />
            )
          )}
        </Route>

        <Route
          path="admissions"
          element={<Layouts.PageLayout useParent={false} />}
        >
          <Route index element={<Pages.Admission />} />
          <Route path="admission-requirement">
            <Route index element={<Pages.AdmissionRequirement />} />
            <Route
              path="undergraduate"
              element={<Pages.AdmissionUndergraduate />}
            />
          </Route>
          <Route
            path="payment-and-banking-details"
            element={<Pages.AdmissionPaymentDetails />}
          />
        </Route>

        <Route
          path="faculty"
          element={<Layouts.PageLayout useParent={false} />}
        >
          <Route index element={<Pages.Faculty />} />
          {Static.Routes.find((f) => f.slug === "faculty")?.children?.map(
            (sub) => (
              <Route key={sub.slug} path={sub.url.replace("/", "")}>
                <Route
                  index
                  element={
                    <Pages.FacultyDetails
                      faculty={Static.Faculties.find(
                        (f) => f.slug === sub.slug
                      )}
                    />
                  }
                />

                {sub.children.map((child) => (
                  <Route
                    key={sub.slug + child.slug}
                    path={child.url.replace("/", "")}
                  >
                    <Route
                      index
                      element={getPage(`Faculty${UCFirst(child.slug)}`, {
                        faculty: Static.Faculties.find(
                          (f) => f.slug === sub.slug
                        ),
                      })}
                    />
                  </Route>
                ))}

                {Static.Departments.filter((d) => d.faculty === sub.slug).map(
                  (dep) => (
                    <Route
                      key={dep.slug}
                      path={`departments/${dep.slug}`}
                      element={
                        <Pages.FacultyDepartmentDetails
                          department={dep}
                          faculty={sub}
                        />
                      }
                    />
                  )
                )}
              </Route>
            )
          )}
        </Route>

        <Route
          path="resources"
          element={
            <Layouts.PageLayout useParent={false} useParentTitle={false} />
          }
        >
          <Route index element={<Pages.Resources data={Static.Resources} />} />
          {Static.Routes.find((f) => f.slug === "resources")?.children?.map(
            (sub) => (
              <Route
                key={sub.slug}
                path={sub.url.replace("/", "")}
                element={getPage(`Resources${UCFirst(sub.slug)}`, {
                  data: Static[UCFirst(sub.slug)],
                })}
              />
            )
          )}
        </Route>

        <Route
          path="postgraduate-school"
          element={<Layouts.PageLayout useParent={false} />}
        >
          <Route index element={<Pages.Postgraduate />} />

          {Static.Routes.find(
            (f) => f.slug === "postgraduate-school"
          )?.children?.map((sub) => (
            <Route
              key={sub.slug}
              path={sub.url.replace("/", "")}
              element={getPage(`Postgraduate${UCFirst(sub.slug)}`, {
                data: sub,
              })}
            />
          ))}
        </Route>

        <Route
          path="news-and-media"
          element={<Layouts.PageLayout indexed={false} />}
        >
          <Route
            index
            element={
              <Pages.NewsMedia
                data={Static.News.sort((n) => n.publishedAt).slice(0, 3)}
              />
            }
          />
          <Route
            path="news/:slug"
            element={<Pages.NewsDetails data={Static.News} />}
          />

          {Static.Routes.find(
            (f) => f.slug === "news-and-media"
          )?.children?.map((sub) => (
            <Route
              key={sub.slug}
              path={sub.url.replace("/", "")}
              element={getPage(`NewsMedia${UCFirst(sub.slug)}`, {
                news: Static.News,
              })}
            />
          ))}
        </Route>

        <Route
          path="contact-us"
          element={<Layouts.PageLayout useParent={false} />}
        >
          <Route index element={<Pages.Contact />} />

          {Static.Routes.find((f) => f.slug === "contact-us")?.children?.map(
            (sub) => (
              <Route
                key={sub.slug}
                path={sub.url.replace("/", "")}
                element={getPage(
                  `Contact${UCFirst(sub.slug)}`,
                  {
                    data: sub,
                  },
                  -1
                )}
              />
            )
          )}
        </Route>
      </Route>

      <Route path="*" element={<Pages.NotFound />} />
    </Routes>
  );
}

export default App;
