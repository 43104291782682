import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import { LinkedDetailCard } from "../../assets/components";

const args = {
  data: {},
};
function Resources(props = { ...args }) {
  props = { ...args, ...props };

  console.log(props);

  return (
    <>
      <h6>
        <em>
          The Ernest Bai Koroma University of Science and Technology Resource
          Center
        </em>
      </h6>

      <Table responsive className="mb-4">
        <thead>
          <tr className="text-muted">
            <th>Resource</th>
            <th>Description</th>
            <th className="text-end">Download</th>
          </tr>
        </thead>
        <tbody>
          {props.data.resource.map((resource, index) => (
            <tr key={index}>
              <td className="fw-semibold">{resource.name}</td>
              <td>{resource.description}</td>
              <td className="text-end">
                <a
                  media="print"
                  target="_blank"
                  rel="noreferrer"
                  download={resource.name}
                  href={`/resources/${resource.link}`}
                  className="btn btn-outline-warning btn-sm"
                >
                  Download
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div>
        <h5 className="fw-bold mb-3">Student Resource Centre</h5>
        {
          <Row className="gy-2">
            {props.data?.related?.map((detail, index) => (
              <Col key={`related_${index}`} md={6} lg={4} xl={3}>
                <LinkedDetailCard
                  target="_blank"
                  title={detail.title}
                  href={detail.link}
                />
              </Col>
            ))}
          </Row>
        }
      </div>

      <div className="mt-4 d-md-none">
        <h5 className="text-primary mb-2">Learn more...</h5>
        <div>
          {[
            { title: "Affiliation", href: "affiliations" },
            { title: "Admission advert", href: "advert" },
          ].map((detail, index) => (
            <LinkedDetailCard
              key={index}
              title={detail.title}
              className="mb-3"
              href={`/resources/${detail.href}`}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default Resources;
