import React from "react";
import { Card, NavLink, Stack } from "react-bootstrap";
import { FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const args = {
  href: "",
  title: "",
  target: "_self",
  rel: "noopener noreferrer",
  color: "var(--bs-black)",
  cardClassName: "",
};

export const LinkedDetailCard = (props = { ...args }) => {
  props = { ...args, ...props };

  return (
    <NavLink
      as={Link}
      to={props.href}
      rel={props.rel}
      target={props.target}
      className={`h-100 ${props.className}`}
    >
      <Card
        className={`card-coloured h-100 ${props.cardClassName}`}
        style={{ "--bg-color": props.color }}
      >
        <Card.Body>
          <Stack
            direction="horizontal"
            className="justify-content-between align-items-center h-100"
          >
            <span className="fw-semibold">{props.title}</span>
            <FaChevronRight style={{ color: props.color }} />
          </Stack>
        </Card.Body>
      </Card>
    </NavLink>
  );
};
