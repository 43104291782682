import React from "react";
import { Data } from "../../assets/statics";
import { LinkedDetailCard } from "../../assets/components";
import { Col, Image, Row } from "react-bootstrap";

function Postgraduate() {
  const { dean, description } = Data.Programs.postgraduate;
  const route = Data.Routes.find(
    (route) => route.slug === "postgraduate-school"
  );
  return (
    <>
      <section className="py-0 mb-3" id="about-faculty">
        <h4 className="text-muted">About the School of Postgraduate Studies</h4>
        <p
          className="text-justify mb-1"
          style={{
            overflow: "hidden",
            lineHeight: "1.5rem",
            maxHeight: "6rem",
          }}
        >
          {description}
        </p>
        <a
          href={`/postgraduate-school/about`}
          className="text-info fw-semibold hover-underline"
        >
          Read more...
        </a>
      </section>

      <section className="p-0 mb-3" id="deanery-faculty">
        <h5 className="text-primary">Office of the Dean</h5>

        <Row className="mt-3">
          <Col md={3}>
            <Image
              src={`/images/deans/${dean?.photo}`}
              alt={`photo_dean`}
              className="img-fluid img-thumbnail"
            />
          </Col>
          <Col>
            <p className="fs-4 fw-bold text-muted mb-0">{dean?.name}</p>
            <p
              className="text-justify m-1"
              style={{
                overflow: "hidden",
                lineHeight: "1.5rem",
                maxHeight: "7.5rem",
              }}
            >
              {dean?.bio}
            </p>
            <a
              href={`/postgraduate-school/office-of-the-dean`}
              className="text-info fw-semibold hover-underline"
            >
              Read more...
            </a>
          </Col>
        </Row>
      </section>

      <Row>
        {[
          { title: "Postgraduate Programs", href: "about#programs" },
          { title: "Admission Requirements", href: "admission-requirements" },
          {
            title: "Payment and Banking Details",
            href: "payment-and-banking-details",
          },
        ].map((detail, index) => (
          <Col sm={6} lg={4} key={index} className="mb-3">
            <LinkedDetailCard
              title={detail.title}
              href={`/postgraduate-school/${detail.href}`}
            />
          </Col>
        ))}
      </Row>

      {route?.children.length > 0 && (
        <div className="mt-4 d-md-none">
          <h5 className="text-primary mb-2">Learn more...</h5>
          <div>
            {route.children.map((r) => (
              <LinkedDetailCard
                key={r.slug}
                title={r.title}
                className="mb-3"
                href={`${route.url}${r.url}`}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default Postgraduate;
