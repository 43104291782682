import React from "react";
import Static from "../../assets/statics";
import { SearchRoutes, toWords } from "../../assets/utils";
import { LinkedDetailCard } from "../../assets/components";
import { Col, Row } from "react-bootstrap";

function Faculty() {
  function getURL(faculty) {
    const got = SearchRoutes(Static.Routes, "slug", faculty.slug);

    return got ? got.url : "";
  }

  return (
    <>
      <p className="fw-semibold mb-4">
        Ernest Bai Koroma University of Science and Technology has{" "}
        {toWords(Static.Faculties.length)} faculties offering a wide range of
        excellent academic and vocational programmes
      </p>

      <Row className="gy-4">
        {Static.Faculties.map((faculty) => (
          <Col lg={6} key={faculty.slug}>
            <LinkedDetailCard
              title={faculty.name}
              color={faculty?.color}
              href={`/faculty${getURL(faculty)}`}
            />
          </Col>
        ))}
      </Row>
    </>
  );
}

export default Faculty;
