import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { portalUrl } from "../assets/utils";

const advert = {
  id: 1,
  title: "2024-2025 Admission is NOW OPEN",
  description:
    "Applicants are hereby invited to apply for admission into postgraduate and undergraduate studies tenable at the university.",
  href: `${portalUrl}/studentcheck.aspx`,
  actionText: "Apply Now",
};

export default function useAdvert() {
  const notified = localStorage.getItem("notified");
  const [show, setShow] = useState(Number(notified) !== advert.id);

  function handleClose() {
    setShow(false);
    localStorage.setItem("notified", advert.id);
  }

  const modal = (
    <Modal
      show={show}
      centered={true}
      onHide={handleClose}
      contentClassName="p-4 rounded-0"
    >
      <Modal.Header className="border-0 justify-content-center pb-0">
        <h5 className="fs-3 fw-bolder">{advert.title}</h5>
      </Modal.Header>
      <Modal.Body className="">
        <p className="fw-semibold">
          Applicants are hereby invited to apply for admission into postgraduate
          and undergraduate studies tenable at the university.
        </p>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-center">
        <Button
          as="a"
          size="lg"
          href={advert.href}
          onClick={(e) => {
            handleClose();
          }}
          target="_blank"
          rel="noreferrer"
          className="d-block w-100 rounded-0 fw-bold"
        >
          {advert.actionText}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return { modal, hasContent: Boolean(advert.id) };
}
