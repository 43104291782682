import React from "react";
import { Stack } from "react-bootstrap";
import { LinkedDetailCard } from "../../assets/components";
import { NewsContent } from "./NewsMediaNews";

const args = {
  data: [],
};

function NewsMedia(props = { ...args }) {
  props = { ...args, ...props };

  return (
    <>
      <Stack gap={4} className="px-3 mb-4">
        {props.data.map((news) => {
          return NewsContent(news, 0, "border-bottom pb-2");
        })}
      </Stack>

      <div className="d-md-none">
        <h5 className="text-primary">Learn more...</h5>
        <div className="mt-2">
          {[
            { title: "More News", href: "news" },
            { title: "Calendar and Events", href: "calendar-and-events" },
          ].map((detail, index) => (
            <LinkedDetailCard
              key={index}
              title={detail.title}
              className="mb-3"
              href={`/news-and-media/${detail.href}`}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default NewsMedia;
