import React, { useMemo } from "react";
import { Accordion, Stack } from "react-bootstrap";
import { Data } from "../../assets/statics";

function Undergraduate() {
  const faculties = useMemo(() => {
    return Data.Faculties.map((f) => ({
      ...f,
      departments: Data.Departments.filter((d) => d.faculty === f.slug).map(
        (d) => ({
          ...d,
          programs: Data.Programs.undergraduate.programs.filter(
            (p) => p.department === d.slug
          ),
        })
      ),
    }));
  }, []);

  return (
    <>
      <section id="programs" className="py-0">
        <div className="mb-3">
          {/* <h5 className="fw-bold mb-0">Programs</h5> */}
          <small>
            <em>Select faculty to see requirements</em>
          </small>
        </div>

        <Accordion defaultActiveKey={0}>
          {faculties.map((faculty, index) => (
            <Accordion.Item
              key={`faculty_${index}`}
              eventKey={index}
              className="rounded-0"
            >
              <Accordion.Header className="fw-semibold">
                <span className="fw-semibold">Faculty of {faculty.name}</span>
              </Accordion.Header>

              <Accordion.Body>
                <Stack gap={2}>
                  {faculty.departments.map((department, idx) => (
                    <div key={`dep_${idx}`}>
                      <small className="fw-semibold text-muted">
                        Department of {department.name}
                      </small>
                      <Stack className="px-3 mt-1" gap={1}>
                        {department.programs.map((program, id) => (
                          <div className="" key={`program_${id}`}>
                            <em className="fw-bold">{program.name}</em>
                            <ol type="i">
                              {program.requirements?.map &&
                                program?.requirements.map((req, key) => (
                                  <li key={`req_${program.department}_${key}`}>
                                    <small>{req}</small>
                                  </li>
                                ))}
                            </ol>
                          </div>
                        ))}
                      </Stack>
                    </div>
                  ))}
                </Stack>
                {/* <ol type="i">
                  {program.requirements?.map &&
                    program?.requirements.map((req, idx) => (
                      <li key={`req_${program.department}_${idx}`}>{req}</li>
                    ))}
                </ol> */}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </section>
    </>
  );
}

export default Undergraduate;
