import React, { useMemo } from "react";
import { Container, Stack } from "react-bootstrap";
import { Breadcrumb, PageAsideNav } from "../assets/components";
import { Outlet, useLocation } from "react-router-dom";
import { Data } from "../assets/statics";
import { ArrayGet, SearchRoutes } from "../assets/utils";

const args = {
  indexed: true,
  useParent: true,
  showAside: true,
  showTitle: true,
  useParentTitle: true,
  showBreadcrumb: true,
};
export default function Page(props = { ...args }) {
  props = { ...args, ...props };

  const { pathname } = useLocation();
  const res = useMemo(
    () =>
      pathname
        .split("/")
        .slice(1)
        .filter((r) => r.length > 0),
    [pathname]
  );
  const page = useMemo(() => {
    const parent = SearchRoutes(Data.Routes, "slug", ArrayGet(res, 0));
    let slug = ArrayGet(res);

    if (slug === "about" && res.length > 1) {
      slug += `-${ArrayGet(res, -res.length)}`;
    }

    return {
      parent,
      ...SearchRoutes(Data.Routes, "slug", slug),
    };
  }, [res]);

  return (
    <Container className="py-4">
      {props.showBreadcrumb && <Breadcrumb className="mb-3" path={res} />}

      <Stack direction="horizontal" className="my-4 align-items-start" gap={4}>
        {props.showAside && (
          <PageAsideNav page={page} useParent={props.useParent} />
        )}

        <Stack>
          {props.showTitle && (
            <h4 className="text-bg-primary px-3 py-2 rounded mb-4">
              {props.useParentTitle ? page?.parent?.title : page?.title}
            </h4>
          )}

          <Outlet />
        </Stack>
      </Stack>
    </Container>
  );
}
