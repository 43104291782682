import React, { useMemo } from "react";
import { portalUrl } from "../assets/utils";
import adverts from "../static/adverts.json";
import { BiCloudDownload } from "react-icons/bi";

export const Slider = () => {
  const advert = useMemo(() => adverts.find((a) => a.isCurrent), []);

  return (
    <div className="cover bg-black">
      <div className="content">
        <div className="container flex-column h-100 text-white">
          <div className="row h-100 align-items-center">
            <div
              className="col-lg-5 text-lg-start text-center"
              style={{ letterSpacing: ".18rem" }}
            >
              <h4 className="display-4">2024&minus;2025</h4>
              <p className="pt-2 fs-5">
                Applicants are hereby invited to apply for admission into
                postgraduate and undergraduate studies tenable at the
                university.
              </p>

              <div className="mt-5 row gy-3 justify-content-lg-start justify-content-center">
                <div className="col-sm">
                  <a
                    href={`${portalUrl}/studentcheck.aspx`}
                    target="_black"
                    rel="no-referrer"
                    className="btn btn-primary text-white fw-bolder py-2 px-4 fs-5 w-100 text-truncate"
                  >
                    APPLY NOW
                  </a>
                </div>

                <div className="col-sm">
                  <a
                    href="tel: 23276780302"
                    target="_black"
                    rel="no-referrer"
                    className="btn btn-outline-info fw-bolder py-2 px-4 fs-5 w-100 text-truncate"
                  >
                    CALL SUPPORT
                  </a>
                </div>

                <div className="col-sm">
                  <a
                    target="_black"
                    rel="no-referrer"
                    href={advert?.courses}
                    className="btn btn-success text-white fw-bolder py-2 px-4 fs-5 w-100 text-truncate text-uppercase d-flex justify-content-center align-items-center"
                  >
                    <span>Course brochure</span>

                    <BiCloudDownload size={32} className="ms-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
