import React, { useMemo } from "react";
import { ListGroup, NavLink, Stack } from "react-bootstrap";
import { GetDropdowns } from "../assets/utils";

const args = {
  page: {},
  useParent: true,
};

export const PageAsideNav = (props = { ...args }) => {
  props = { ...args, ...props };

  const children = useMemo(() => {
    let children = props.page.parent?.children;

    if (!props.page.isParent) {
      if (!props.useParent) children = props.page?.children;
    }

    return children?.map ? children : [];
  }, [props]);

  const isGrandSibling = useMemo(() => {
    return props.page.parent?.children?.[0]?.slug !== children?.[0]?.slug;
  }, [children, props]);

  return (
    (children.length > 0 || props.useParent) && (
      <ListGroup
        typeof="flush"
        className="border-bottom d-none d-md-inline"
        style={{ minWidth: "226px" }}
      >
        {children.map((child, index) => (
          <Stack key={`aside_${index}`} className="border-top py-1 px-2">
            {child?.children?.length > 0 ? (
              GetDropdowns(child, "end", props.page.parent, "text-wrap")
            ) : (
              <NavLink
                href={
                  child?.href ||
                  `${props.page?.parent?.url}${
                    isGrandSibling ? props.page?.url : ""
                  }${child.url}`
                }
                key={child.slug}
              >
                <span>{child.title}</span>
              </NavLink>
            )}
          </Stack>
        ))}
      </ListGroup>
    )
  );
};
