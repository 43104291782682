import React from "react";
import { Data } from "../../assets/statics";
import { Accordion, Col, Image, Row } from "react-bootstrap";

function PostgraduateAbout() {
  const { programs, dean, description, requirements } =
    Data.Programs.postgraduate;

  function getRequirements(program) {
    const slug =
      program.name?.indexOf("Master") > -1
        ? "masters"
        : program.name?.indexOf("Diploma") > -1
        ? "diploma"
        : "PhD";

    const req = requirements?.[slug] || [];

    return [...req, ...program.requirements];
  }

  return (
    <>
      <div
        className="mb-3 text-justify"
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>

      <section className="p-0 mb-3" id="deanery">
        <h5 className="text-primary fw-bold">Office of the Dean</h5>

        <Row className="mt-3">
          <Col md className="order-1 order-sm-0">
            <p className="fs-4 fw-bold text-muted mb-0">{dean?.name}</p>
            <p
              className="text-justify m-1"
              style={{
                overflow: "hidden",
                lineHeight: "1.5rem",
                maxHeight: "7.5rem",
              }}
            >
              {dean?.bio}
            </p>
            <a
              href={`/postgraduate-school/office-of-the-dean`}
              className="text-info fw-semibold hover-underline"
            >
              Read more...
            </a>
          </Col>

          <Col md={3} className="order-0 order-sm-1">
            <Image
              src={`/images/deans/${dean?.photo}`}
              alt={`photo_dean`}
              className="img-fluid img-thumbnail"
            />
          </Col>
        </Row>
      </section>

      <section id="programs" className="py-0">
        <div className="mb-3">
          <h5 className="text-primary fw-bold mb-0">Programs</h5>
          <small>
            <em>Select program to see requirements</em>
          </small>
        </div>

        <Accordion defaultActiveKey={0}>
          {programs.map((program, index) => (
            <Accordion.Item
              key={`program_${index}`}
              eventKey={index}
              className="rounded-0"
            >
              <Accordion.Header>
                <span className="fw-semibold">{program.name}</span>
              </Accordion.Header>

              <Accordion.Body>
                <div>
                  <strong>Program Duration</strong>
                  <p className="lead">{program?.duration}</p>
                </div>

                <div>
                  <strong>Campus</strong>
                  <p className="lead">{program?.campus}</p>
                </div>

                <div>
                  <strong>Options</strong>
                  <ol type="i">
                    {program.options?.map &&
                      program?.options.map((option, idx) => (
                        <li key={`option_${idx}`}>{option}</li>
                      ))}
                  </ol>
                </div>

                <div>
                  <strong>Entry Requirements</strong>
                  <ol type="i">
                    {getRequirements(program).map((req, idx) => (
                      <li key={`req_${idx}`}>{req}</li>
                    ))}
                  </ol>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </section>
    </>
  );
}

export default PostgraduateAbout;
